import React from "react";
import { Box, Typography } from "@mui/material";
import UploadArea from "./UploadArea";
import { useIntl } from "react-intl";

function MediaUpload({ files, handleFileOnChange }) {
  const intl = useIntl(); // Inicialización de intl para traducción

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        gap: 2,
        width: "100%",
      }}
    >
      {/* Título */}
      <Typography
        variant="h5"
        fontWeight="bold"
        color="text.primary"
        sx={{ width: "100%" }}
      >
        {intl.formatMessage({ id: "mediaUpload.title", defaultMessage: "Media" })}
      </Typography>

      {/* Descripción */}
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ mt: 1, width: "100%" }}
      >
        {intl.formatMessage({ id: "mediaUpload.description", defaultMessage: "Agrega tus documentos aquí, máximo 5 archivos" })}{" "}
        <Typography component="span" fontWeight="medium" color="text.disabled">
          *
        </Typography>
      </Typography>

      {/* Área de carga */}
      <UploadArea onFileChange={handleFileOnChange} />

      {/* Tipos de archivo soportados */}
      <Typography
        variant="body2"
        color="text.disabled"
        sx={{
          mt: 2,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          maxWidth: "100%",
        }}
      >
        {intl.formatMessage({ id: "mediaUpload.supportedFileTypes", defaultMessage: "Solo soporta .jpg, .png, .svg, y archivos zip" })}
      </Typography>
    </Box>
  );
}

export default MediaUpload;
