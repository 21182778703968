import { supabase } from "../lib/helper/supabaseClient";

export const fetchAllGenres = async () => {
    try {
      const { data, error } = await supabase.from("genre").select("*");
      console.log("Data received from Supabase:", data); // Verifica qué se recibe
      
  
      if (error) {
        console.log("Error received from Supabase:", error); // Verifica si hay un error
        throw error;
      }
  
      if (data.length === 0) {
        console.warn("The genre table is empty or no data is being fetched.");
      }
  
      return data.map((genre) => genre.name);
    } catch (error) {
      console.error("Error fetching genres:", error.message);
      throw error;
    }
  };
  

export const registerGenres = async (genres) => {
  try {
    for (const genre of genres) {
      const { error } = await supabase.from("genre").insert({ name: genre, image: "default_image_url" });
      if (error) {
        console.error("Error al insertar género:", genre, error);
        return { success: false, message: `Error al registrar el género ${genre}. Inténtalo más tarde.` };
      }
    }
    return { success: true };
  } catch (error) {
    console.error("Error inesperado al registrar géneros:", error.message || error);
    return { success: false, message: "Ocurrió un error inesperado al registrar géneros. Por favor, inténtalo más tarde." };
  }
};
