import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Grid,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import { useIntl } from "react-intl";

import { getFarosByClientID, deleteFaro } from "../functions/faro";
import { supabase } from "../lib/helper/supabaseClient";
import EventCard from "../components/EventCard/EventCard";
import VenueCard from "../components/VenueCard/VenueCard";
import { fetchVenueFieldsById, updateVenueFields } from "../functions/client";
import { useNavigate } from "react-router-dom";

export default function ClientDashboard() {
  const intl = useIntl(); // Inicialización de `intl`
  const [events, setEvents] = useState([]);
  const [user, setUser] = useState(null);
  const [venues, setVenues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const navigate = useNavigate();

  const fetchEvents = async () => {
    if (user) {
      try {
        let faros = await getFarosByClientID(user.id);
        setEvents(faros);
      } catch (error) {
        console.error("Error fetching events:", error);
        setSnackbar({
          open: true,
          message: intl.formatMessage({ id: "clientDashboard.errorEvents", defaultMessage: "Error loading events" }),
          severity: "error",
        });
      }
    }
  };

  const fetchVenues = async () => {
    if (user) {
      try {
        let venueData = await fetchVenueFieldsById(user.id);
        setVenues(Array.isArray(venueData) ? venueData : [venueData]);
      } catch (error) {
        console.error("Error fetching venues:", error);
        setSnackbar({
          open: true,
          message: intl.formatMessage({ id: "clientDashboard.errorVenues", defaultMessage: "Error loading venues" }),
          severity: "error",
        });
      }
    }
  };

  const fetchUser = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUser(user);
    } catch (error) {
      console.error("Error fetching user:", error);
      setSnackbar({
        open: true,
        message: intl.formatMessage({ id: "clientDashboard.errorUserInfo", defaultMessage: "Error loading user information" }),
        severity: "error",
      });
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      Promise.all([fetchVenues(), fetchEvents()])
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [user]);

useEffect(() => {
  const fetchUserAndEvents = async () => {

    const {
      data: { user },
    } = await supabase.auth.getUser();
    setUser(user);
      if (user) {
        let faros = await getFarosByClientID(user.id);
        setEvents(faros);
      }
    };
    fetchUserAndEvents();
  }, []);

  const handleDeleteEvent = async (eventId) => {
    try {
      await deleteFaro(eventId);
      setEvents((prevEvents) =>
        prevEvents.filter((event) => event.id !== eventId)
      );
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const updateVenueInfo = async (updatedVenue) => {
    try {
      const response = await updateVenueFields(user.id, updatedVenue);
      if (response.success) {
        setVenues((prevVenues) =>
          prevVenues.map((venue) =>
            venue.id === updatedVenue.id ? updatedVenue : venue
          )
        );
        setSnackbar({
          open: true,
          message: intl.formatMessage({ id: "clientDashboard.venueUpdatedSuccess", defaultMessage: "Venue updated successfully" }),
          severity: "success",
        });
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.error("Failed to update venue:", error);
      setSnackbar({
        open: true,
        message: intl.formatMessage({ id: "clientDashboard.venueUpdateError", defaultMessage: "Error updating venue" }),
        severity: "error",
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <>
        <Box sx={{ backgroundColor: "#171717", height: "4vh" }}></Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Typography>
            {intl.formatMessage({ id: "clientDashboard.loading", defaultMessage: "Loading..." })}
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#f5f5f5", minHeight: "100vh" }}>
      <Box sx={{ backgroundColor: "#171717", height: "4vh" }}></Box>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            fontWeight: "bold",
            color: "#333",
            borderBottom: "2px solid #af8e56",
            paddingBottom: "8px",
            marginBottom: "24px",
          }}
        >
          {intl.formatMessage({ id: "clientDashboard.venues", defaultMessage: "Venues" })}
        </Typography>
        {venues.length > 0 ? (
          <Grid container spacing={3}>
            {venues.map((venue) => (
              <Grid item xs={12} sm={6} md={3} key={venue.id}>
                <VenueCard venue={venue} onUpdate={updateVenueInfo} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body1">
            {intl.formatMessage({ id: "clientDashboard.noVenues", defaultMessage: "No venues found." })}
          </Typography>
        )}
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            fontWeight: "bold",
            color: "#333",
            borderBottom: "2px solid #af8e56",
            paddingBottom: "8px",
            marginBottom: "24px",
            marginTop: "48px",
          }}
        >
          {intl.formatMessage({ id: "clientDashboard.eventsCreated", defaultMessage: "Created Events" })}
        </Typography>
        {events.length > 0 ? (
          <Grid container spacing={3}>
            {events.map((event) => (
              <Grid item xs={12} sm={6} md={3} key={event.id}>
                <EventCard event={event} onDelete={handleDeleteEvent} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body1">
            {intl.formatMessage({ id: "clientDashboard.noEvents", defaultMessage: "No events found." })}
          </Typography>
        )}
      </Container>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
