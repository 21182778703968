import enHomepage from './en/Homepage.json';
import esHomepage from './es/Homepage.json';
import enHeader from './en/Header.json';
import esHeader from './es/Header.json';
import enFooter from './en/Footer.json';
import esFooter from './es/Footer.json';
import enSearchBar from './en/Searchbar.json';
import esSearchBar from './es/Searchbar.json';
import enArtistSearchScreen from './en/ArtistSearchScreen.json';
import esArtistSearchScreen from './es/ArtistSearchScreen.json';
import enArtistCard from './en/ArtistCard.json';
import esArtistCard from './es/ArtistCard.json';
import enArtistProfileScreen from './en/ArtistProfileScreen.json';
import esArtistProfileScreen from './es/ArtistProfileScreen.json';
import enPriceCalculator from './en/PriceCalculator.json';
import esPriceCalculator from './es/PriceCalculator.json';
import enCreateFaroPage from './en/CreateFaroPage.json';
import esCreateFaroPage from './es/CreateFaroPage.json';
import enEventForm from './en/EventForm.json';
import esEventForm from './es/EventForm.json';
import enCategorySelection from './en/CategorySelection.json';
import esCategorySelection from './es/CategorySelection.json';
import enClientDashboard from './en/ClientDashboard.json';
import esClientDashboard from './es/ClientDashboard.json';
import enEventCard from './en/EventCard.json';
import esEventCard from './es/EventCard.json';


const flattenMessages = (nestedMessages, prefix = '') => {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'object' && value !== null) {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    } else {
      messages[prefixedKey] = value;
    }

    return messages;
  }, {});
};

export const messages = {
  en: {
    ...flattenMessages({
      homepage: enHomepage.homepage,
      footer: enFooter.footer,

    }),
    ...enHeader,
    ...enSearchBar,
    ...enArtistSearchScreen,
    ...enArtistCard,
    ...enArtistProfileScreen,
    ...enPriceCalculator,
    ...enCreateFaroPage,
    ...enEventForm,
    ...enCategorySelection,
    ...enClientDashboard,
    ...enEventCard,
  },
  es: {
    ...flattenMessages({
      homepage: esHomepage.homepage,
      footer: esFooter.footer,

    }),
    ...esHeader,
    ...esSearchBar,
    ...esArtistSearchScreen,
    ...esArtistCard,
    ...esArtistProfileScreen,
    ...esPriceCalculator,
    ...esCreateFaroPage,
    ...esEventForm,
    ...esCategorySelection,
    ...esClientDashboard,
    ...esEventCard,
  },
};
