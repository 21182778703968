import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getArtistNameById } from "../../functions/artist";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

const EventCard = ({ event, onDelete }) => {
  const intl = useIntl();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [artistName, setArtistName] = useState(intl.formatMessage({ id: "eventCard.loading", defaultMessage: "Loading..." }));
  const navigate = useNavigate();

  const handleModify = () => {
    navigate(`/faro_creation/${event.id}`);
  };

  useEffect(() => {
    const fetchArtistName = async () => {
      try {
        const artist = await getArtistNameById(event.artistID);
        if (artist && artist.stageName) {
          setArtistName(artist.stageName);
        } else {
          setArtistName(intl.formatMessage({ id: "eventCard.unknown", defaultMessage: "Unknown" }));
        }
      } catch (error) {
        console.error("Error fetching artist:", error);
        setArtistName(intl.formatMessage({ id: "eventCard.unknown", defaultMessage: "Unknown" }));
      }
    };

    if (event.artistID) {
      fetchArtistName();
    } else {
      setArtistName(intl.formatMessage({ id: "eventCard.notDefined", defaultMessage: "Not defined" }));
    }
  }, [event.artistID, intl]);

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = async () => {
    if (onDelete) {
      await onDelete(event.id);
    }
    setOpenDeleteDialog(false);
  };

  const formattedDate = event.eventDate
    ? new Date(event.eventDate).toLocaleDateString(intl.locale, {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : intl.formatMessage({ id: "eventCard.notDefined", defaultMessage: "Not defined" });

  return (
    <Card
      sx={{
        maxWidth: 345,
        borderRadius: 2,
        overflow: "hidden",
        border: "1px solid #d0d0d0",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {event.image && (
        <CardMedia
          component="img"
          height="140"
          image={event.image}
          sx={{
            borderTopLeftRadius: 2,
            borderTopRightRadius: 2,
          }}
        />
      )}

      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {event.name || intl.formatMessage({ id: "eventCard.noName", defaultMessage: "Unnamed Event" })}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          <strong>{intl.formatMessage({ id: "eventCard.date", defaultMessage: "Date:" })}</strong> {formattedDate}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          <strong>{intl.formatMessage({ id: "eventCard.budget", defaultMessage: "Budget:" })}</strong>{" "}
          {event.payment ? `$${event.payment}` : intl.formatMessage({ id: "eventCard.notDefined", defaultMessage: "Not defined" })}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          <strong>{intl.formatMessage({ id: "eventCard.address", defaultMessage: "Address:" })}</strong>{" "}
          {event.address || intl.formatMessage({ id: "eventCard.notDefined", defaultMessage: "Not defined" })}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          <strong>{intl.formatMessage({ id: "eventCard.artist", defaultMessage: "Artist:" })}</strong> {artistName}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mt: 2,
          }}
        >
          <IconButton
            aria-label="edit"
            sx={{ color: "#FFD700" }}
            onClick={handleModify}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            aria-label="delete"
            sx={{ color: "red" }}
            onClick={handleDeleteClick}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </CardContent>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>
          {intl.formatMessage({ id: "eventCard.confirmDelete", defaultMessage: "Do you really want to delete this Faro?" })}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            {intl.formatMessage({ id: "eventCard.cancelDelete", defaultMessage: "Don't delete" })}
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary" autoFocus>
            {intl.formatMessage({ id: "eventCard.confirmDeleteYes", defaultMessage: "Yes, I'm sure" })}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default EventCard;
