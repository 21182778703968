import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import jsPDF from "jspdf";
import { useIntl } from "react-intl";

const EventQuote = ({ artistName, genres, hourlyRate, date, timeRange, complements, total }) => {
  const intl = useIntl(); // Inicialización de intl para traducción

  const handleDownload = () => {
    const doc = new jsPDF();
    doc.text(`${intl.formatMessage({ id: "eventQuote.pdfTitle", defaultMessage: "Quote for" })} ${artistName || intl.formatMessage({ id: "eventQuote.defaultEvent", defaultMessage: "Event" })}`, 10, 10);
    doc.text(`${intl.formatMessage({ id: "eventQuote.genres", defaultMessage: "Genres" })}: ${genres.join(", ")}`, 10, 20);
    doc.text(`${intl.formatMessage({ id: "eventQuote.hourlyRate", defaultMessage: "Hourly rate" })}: ${hourlyRate.toLocaleString()} mil pesos`, 10, 30);
    doc.text(`${intl.formatMessage({ id: "eventQuote.eventDate", defaultMessage: "Event date" })}: ${date}`, 10, 40);
    doc.text(`${intl.formatMessage({ id: "eventQuote.eventTime", defaultMessage: "Event time" })}: ${timeRange}`, 10, 50);
    doc.text(`${intl.formatMessage({ id: "eventQuote.complements", defaultMessage: "Complements" })}:`, 10, 60);
    complements.forEach((comp, index) => {
      doc.text(`- ${comp.name}: ${comp.price.toLocaleString()} mil pesos`, 10, 70 + index * 10);
    });
    doc.text(`${intl.formatMessage({ id: "eventQuote.total", defaultMessage: "Total" })}: ${total.toLocaleString()} mil pesos`, 10, 100);
    doc.save(`cotizacion_${artistName || "evento"}.pdf`);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        padding: 4,
        maxWidth: "400px",
        margin: "auto",
        fontFamily: "Poppins",
      }}
    >
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", color: "#b68e00", marginBottom: 2 }}
      >
        {intl.formatMessage({ id: "eventQuote.summaryTitle", defaultMessage: "Your purchase summary" })}
      </Typography>
      <Divider />
      <Box sx={{ marginY: 2 }}>
        <Typography sx={{ fontWeight: "bold" }}>
          {artistName} ({genres.join(", ")})
        </Typography>
        <Typography>
          {intl.formatMessage({ id: "eventQuote.hourlyRate", defaultMessage: "Hourly rate" })}{" "}
          <strong style={{ color: "#000" }}>
            ${hourlyRate.toLocaleString()} mil pesos
          </strong>
        </Typography>
        <Typography>
          {intl.formatMessage({ id: "eventQuote.eventDate", defaultMessage: "Event date" })}{" "}
          <strong>{date}</strong>
        </Typography>
        <Typography>
          {intl.formatMessage({ id: "eventQuote.eventTime", defaultMessage: "Event time" })}{" "}
          <strong>{timeRange}</strong>
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ marginY: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {intl.formatMessage({ id: "eventQuote.total", defaultMessage: "Total" })}{" "}
          <strong style={{ color: "#000" }}>
            ${total.toLocaleString()} mil pesos
          </strong>
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 3,
        }}
      >
        <DownloadIcon sx={{ marginRight: 1, color: "#000" }} />
        <Typography
          sx={{ fontWeight: "bold", cursor: "pointer" }}
          onClick={handleDownload}
        >
          {intl.formatMessage({ id: "eventQuote.download", defaultMessage: "Download quote" })}
        </Typography>
      </Box>
    </Box>
  );
};

export default EventQuote;
