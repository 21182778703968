import React, { useState, useEffect } from 'react';
import { Box, Typography, Card } from '@mui/material';
import { styled } from '@mui/system';
import TimeSelection from '../FaroCreationComponents/TimeSelection';
import { useIntl } from 'react-intl'; // Importación para traducción

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#1e1e1e',  
  color: '#fff',               
  borderRadius: '15px',
  padding: theme.spacing(3),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',  
}));

const PriceCalculator = ({ rate, onDateChange, onStartTimeChange, onEndTimeChange }) => {
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [date, setDate] = useState('');
  const [price, setPrice] = useState(0);
  const intl = useIntl();

  const formatPrice = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true
    }).format(value).replace(/,/g, '.'); 
  };

  useEffect(() => {
    if (startTime && endTime) {
      const start = new Date(`1970-01-01T${startTime}`);
      const end = new Date(`1970-01-01T${endTime}`);
      const durationInHours = (end - start) / (1000 * 60 * 60);

      if (durationInHours > 0) {
        const totalCost = durationInHours * rate * 1.3;
        setPrice(formatPrice(totalCost));
      } else {
        setPrice(formatPrice(0));
      }
    } else {
      setPrice(formatPrice(0));
    }
  }, [startTime, endTime, rate]);

  useEffect(() => {
    if (onDateChange) {
      onDateChange(date);
    }
  }, [date, onDateChange]);

  useEffect(() => {
    if (onStartTimeChange) {
      onStartTimeChange(startTime);
    }
  }, [startTime, onStartTimeChange]);

  useEffect(() => {
    if (onEndTimeChange) {
      onEndTimeChange(endTime);
    }
  }, [endTime, onEndTimeChange]);

  return (
    <StyledCard>
      <Typography variant="h5" gutterBottom>
        {intl.formatMessage({ id: "priceCalculator.title", defaultMessage: "Calcular el precio de contratación" })}
      </Typography>
      <TimeSelection
        onDateChange={setDate}
        startTimeValue={startTime}
        onStartTimeChange={setStartTime} 
        endTimeValue={endTime}
        onEndTimeChange={setEndTime} 
        themeD="dark"     
      />
      <Typography variant="h6" sx={{ mt: 2 }}>
        {intl.formatMessage({ id: "priceCalculator.total", defaultMessage: "Total:" })} ${price}
      </Typography>
    </StyledCard>
  );
};

export default PriceCalculator;
