import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  CardMedia
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import { gold } from "../lib/colors";
import imagenBaladas from "../assets/images/GENEROS/baladas.jpg";
import imagenPop from "../assets/images/GENEROS/pop.jpg";
import imagenAcustico from "../assets/images/GENEROS/acústico.jpg";
import imagenReggaeton from "../assets/images/GENEROS/reggaeton.jpg";
import imagenRock from "../assets/images/GENEROS/rock.jpg";
import imagenSalsa from "../assets/images/GENEROS/salsa.jpg";
import imagenBaladas2 from "../assets/images/GENEROS/baladas2.png";
import imagenBolero from "../assets/images/GENEROS/bolero.jpg";
import imagenElectronica from "../assets/images/GENEROS/electronica.jpg";
import imagenFlamenco from "../assets/images/GENEROS/flamenco.jpg";
import imagenFunk from "../assets/images/GENEROS/funk.jpg";
import imagenIndie from "../assets/images/GENEROS/indie.jpg";
import imagenJazz from "../assets/images/GENEROS/jazz.jpg";
import imagenRanchera from "../assets/images/GENEROS/ranchera.jpg";
import imagenRnb from "../assets/images/GENEROS/rnb.jpg";
import BookNowButton from "../components/BookNowButton/BookNowButton";
import PriceCalculator from "../components/artistComponents/PriceCalculator";
import VideoEmbed from "../components/artistComponents/EmbeddedVideo";
import { extractDomainName } from "../functions/stringManipulation";
import ArtistStats from "../components/artistComponents/ArtistStats";
import PSN from "../assets/images/PSN.png";
import estrellaNO from "../assets/images/estrellaNo.png";
import brillo from "../assets/images/brillo.PNG";
import { getArtistsStageName2 } from "../functions/artist";
import { useIntl } from "react-intl";


const Root = styled("div")({
  backgroundColor: "#171717",
  color: "#fff",
  minHeight: "100vh",
  paddingTop: "96px",
  fontFamily: "Poppins",
  backgroundImage: `url(${brillo})`,
  backgroundSize: '120vh',
  backgroundPosition: 'right 0px top 0px',
  backgroundRepeat: 'no-repeat'
});

const TypographyStyled = styled(Typography)({
  fontFamily: "Poppins"
});

const TypographyStyledP = styled(Typography)({
  fontFamily: "Poppins",
  color: "#e5e5e5",
  fontWeight: 100,
  fontSize: "18px",
});

const ArtistName = styled(Typography)(({ theme }) => ({
  fontSize: "70px",
  marginBottom: "20px",
  fontFamily: "Poppins",
  [theme.breakpoints.down('sm')]: {
    fontSize: "3rem",
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#272727",
  color: "#fff",
  marginBottom: "10px",
  borderRadius: "15px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
  fontFamily: "Poppins",
  border: "1px solid rgba(229, 229, 229, 0.32)"
}));

const GenreCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#1e1e1e",
  color: "#fff",
  borderRadius: "15px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
  height: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Poppins",
  border: "1px solid rgba(255, 255, 255, 0.32)",
}));

const GenreImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "15px",
  border: "1px solid rgba(255, 255, 255, 0.32)",
});

const genreImages = {
  "baladas": imagenBaladas,
  "pop": imagenPop,
  "acustico": imagenAcustico,
  "reggaeton": imagenReggaeton,
  "rock": imagenRock,
  "salsa": imagenSalsa,
  "baladas2": imagenBaladas2,
  "bolero": imagenBolero,
  "electronica": imagenElectronica,
  "flamenco": imagenFlamenco,
  "funk": imagenFunk,
  "indie": imagenIndie,
  "jazz": imagenJazz,
  "ranchera": imagenRanchera,
  "rnb": imagenRnb
};

const ArtistProfileScreen = () => {
  const { artistName } = useParams();
  const [date, setDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const [artist, setArtist] = useState(location.state?.artist || null);
  const intl = useIntl();
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchArtist = async () => {
      try {
        const fetchedArtist = await getArtistsStageName2(artistName);
        if (fetchedArtist && fetchedArtist.stageName) {
          setArtist(fetchedArtist);
        } else {
          setArtist(false);
        }
      } catch (error) {
        console.error("Error fetching artist data:", error);
        setArtist(false);

      }
    };

    if (artist === null) fetchArtist();
  }, [artistName]);

  if (artist === false) {
    return (
      <div>
        <h2>{intl.formatMessage({ id: "artistNotFound", defaultMessage: "Artista no encontrado" })}</h2>
        <Button onClick={() => navigate("/")}>
          {intl.formatMessage({ id: "backToHome", defaultMessage: "Volver a Inicio" })}
        </Button>
      </div>
    );
  } else if (artist === null) {
    return <h2>{intl.formatMessage({ id: "loading", defaultMessage: "Cargando..." })}</h2>;
  }


  const platform = extractDomainName(artist.socials);
  const getGenresWithImages = () => {
    const genres = artist.genres || [];
    const genreImagesToShow = genres.slice(0, 3).map((genre) => {
      const lowerCaseGenre = genre.toLowerCase();

      if (lowerCaseGenre === "balada") {
        return imagenBaladas;
      }

      const image = genreImages[lowerCaseGenre];
      if (!image) {
        console.log(`No se encontró imagen para el género: ${lowerCaseGenre}`);
      }
      return image || imagenBaladas;
    });


    if (genreImagesToShow.length === 0) {
      genreImagesToShow.push(imagenBaladas);
    }

    return genreImagesToShow;
  };



  const genreImagesToShow = getGenresWithImages();

  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const showName = (str) => {
    if (!str) return <></>;
    const words = str.split(" ");
    if (words.length > 1) {
      const lastName = words.pop();
      const firstName = words.join(" ");
      return (
        <>
          {capitalizeWords(firstName)}{" "}
          <span style={{ color: gold, fontWeight: 700 }}>{capitalizeWords(lastName)}</span>
        </>
      );
    }

    return <>{capitalizeWords(str)}</>;
  };

  return (
    <>
      <Root sx={{ paddingX: { xs: '5vw', sm: '10vw', md: '10vw', lg: "2vw", xl: "15vw" }, paddingBottom: 15 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "flex-start",
            alignItems: { xs: "flex-start", md: "center" },
            gap: 5,
            mb: 5,
          }}
        >
          <ArtistName>{artist && artist.stageName ? showName(artist.stageName) : "Cargando..."}</ArtistName>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 0,
              width: { xs: '100%', md: 'auto' },
            }}
          >
            <BookNowButton
              onClick={() => navigate("/faro_creation", { state: { artist } })}
              fullWidth={"xs"}
            />
          </Box>
        </Box>


        <Grid container spacing={2}>
          <Grid item xs={12} container style={{ display: 'flex', alignItems: 'stretch' }} spacing={2}>
            <Grid item xs={12} md={4} style={{ display: 'flex' }}>
              <StyledCard style={{ width: '100%' }}>
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    paddingBottom: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={artist.profile_picture}
                    alt={artist.stageName}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      borderRadius: '10px',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              </StyledCard>
            </Grid>

            <Grid item md={8} style={{ display: 'flex' }}>
              <StyledCard  >
                <CardContent sx={{ paddingX: 4, paddingTop: 5 }}>
                  <TypographyStyled variant="h5" sx={{ fontWeight: 700, mb: 3 }} gutterBottom>
                    {intl.formatMessage({ id: "whoAmI", defaultMessage: "¿Quién soy?" })}
                  </TypographyStyled>
                  <TypographyStyledP >{artist.description}</TypographyStyledP>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2} style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Grid item xs={12} sm={12} md={4} style={{ display: 'flex' }}>
              <ArtistStats
                artist={artist}
                StyledCard={StyledCard}
                style={{ width: '100%', maxHeight: '234px' }}
              />
            </Grid>



            <Grid container item md={8} spacing={2}>
              {genreImagesToShow.map((image, index) => (
                <Grid
                  item
                  xs={genreImagesToShow.length === 1 ? 12 : genreImagesToShow.length === 2 ? 6 : 4}
                  key={index}
                  style={{ display: 'flex' }}
                >
                  <GenreCard
                    style={{
                      flexGrow: 1,
                      height: '100%',
                      maxHeight: '220px',
                      margin: genreImagesToShow.length > 1 && index % 3 === 1 ? '0px 8px' : '0px',
                    }}
                  >
                    <GenreImage
                      src={image}
                      alt={`genre-${index}`}
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </GenreCard>
                </Grid>
              ))}
            </Grid>
          </Grid>


        </Grid>

        <Grid container spacing={2} style={{ display: 'flex', alignItems: 'stretch', paddingTop: 30 }}>
          <Grid item xs={12} md={12} lg={8}>
            <StyledCard>
              <VideoEmbed embedUrl={artist.socials} platform={platform} />
            </StyledCard>
          </Grid>

          <Grid container item xs={12} md={12} lg={4} style={{ marginTop: { xs: 16, md: 0, lg: 0 } }}>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
              <StyledCard style={{ marginBottom: '20px' }}>
                <Grid container>
                  <Grid
                    item
                    xs={2.5}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      pr: 2,
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ width: 'auto', maxHeight: '45px', objectFit: 'contain' }}
                      image={estrellaNO}
                      alt="Estrella"
                    />
                  </Grid>
                  <Grid item xs={9.5} sx={{ marginY: 3, pr: 2 }}>
                    <Typography gutterBottom sx={{ fontWeight: 'bold', fontSize: 15, fontFamily: 'Poppins' }}>
                      {intl.formatMessage({ id: "featuredStages", defaultMessage: "Escenarios destacados donde se ha presentado" })}
                    </Typography>
                    <Typography sx={{ fontWeight: 100, fontFamily: 'Poppins', fontSize: 15 }}>
                      Club Rítmico, Bogotá<br />Bistro Melódico, Medellín
                    </Typography>
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>

            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
              <StyledCard style={{ flexGrow: 1, width: '100%' }}>
                <Grid container sx={{ marginY: 3, pr: 2 }}>
                  <Grid item xs={2.5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CardMedia
                      component="img"
                      sx={{ width: 'auto', maxHeight: '50px', objectFit: 'contain' }}
                      image={PSN}
                      alt="Performance Setup"
                    />
                  </Grid>
                  <Grid item xs={9.5} sx={{ marginY: 2, pr: 2 }}>
                    <Typography gutterBottom sx={{ fontWeight: 'bold', fontSize: 18, fontFamily: 'Poppins' }}>
                      {intl.formatMessage({ id: "performanceSetup", defaultMessage: "Configuración de la Presentación" })}
                    </Typography>
                    <Typography sx={{ fontWeight: 100, fontFamily: 'Poppins' }}>
                      <strong>{intl.formatMessage({ id: "presentationType", defaultMessage: "Tipo de Presentación:" })}</strong> {intl.formatMessage({ id: "presentationTypeValue", defaultMessage: "Canciones de cover" })}<br />
                      <strong>{intl.formatMessage({ id: "accompaniment", defaultMessage: "Acompañamiento:" })}</strong> {intl.formatMessage({ id: "accompanimentValue", defaultMessage: "Presentación solista con pistas de acompañamiento opcionales o banda en vivo" })}<br />
                      <strong>{intl.formatMessage({ id: "equipment", defaultMessage: "Equipo:" })}</strong> {intl.formatMessage({ id: "equipmentValue", defaultMessage: "Guitarra acústica, micrófono, sistema PA (si lo provee el lugar)" })}
                    </Typography>

                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>
          </Grid>
        </Grid>


        <PriceCalculator
          rate={artist.rate}
          onDateChange={setDate}
          onStartTimeChange={setStartTime}
          onEndTimeChange={setEndTime}
        />

        <BookNowButton
          onClick={() =>
            navigate("/faro_creation", {
              state: {
                artist,
                date,
                startTime,
                endTime,
              },
            })
          }
        />
      </Root >
    </>
  );
};

export default ArtistProfileScreen;