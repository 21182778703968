import React from 'react';
import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { gold, white } from '../../lib/colors';

const BookNowButton = ({ onClick }) => {
  const intl = useIntl();

  return (
    <Button
      variant="contained"
      onClick={onClick}
      fullWidth
      sx={{
        backgroundColor: gold,
        color: white,
        borderRadius: '9px',
        padding: '7px 24px',
        fontSize: '14px',
        '&:hover': {
          backgroundColor: '#9a7209',
        },
      }}
    >
      {intl.formatMessage({ id: "bookNowButtontext", defaultMessage: "Contratar ahora" })}
    </Button>
  );
};

export default BookNowButton;
