import { supabase } from "../lib/helper/supabaseClient";
import { getArtistByID } from "./artist";
import { getClientByID } from "./client";

// Function to check if the username is already taken in both artist and client tables
export const checkUsernameAvailability = async (username) => {
  // Check in 'artist' table
  let { data: artistData, error: artistError } = await supabase
    .from("artist")
    .select("username")
    .eq("username", username);

  if (artistError) {
    console.error("Error fetching artist data:", artistError);
    return false;
  }

  // Check in 'client' table
  let { data: clientData, error: clientError } = await supabase
    .from("client")
    .select("username")
    .eq("username", username);

  if (clientError) {
    console.error("Error fetching client data:", clientError);
    return false;
  }

  // Safely handle null values for both artistData and clientData
  if (
    (artistData && artistData.length > 0) ||
    (clientData && clientData.length > 0)
  ) {
    return false; // Username is already taken
  }

  return true; // Username is available
};

// Function to check if the venueName is already taken in the client table
export const checkVenueNameAvailability = async (venueName) => {
  // Check in 'client' table for venueName
  let { data: venueData, error: venueError } = await supabase
    .from("client")
    .select("venueName")
    .eq("venueName", venueName);

  if (venueError) {
    console.error("Error fetching venue data:", venueError);
    return false;
  }

  if (venueData && venueData.length > 0) {
    return false; // Venue name is already taken
  }

  return true; // Venue name is available
};

// Function to check if the phoneNumber is already taken in the client table
export const checkPhoneNumberAvailability = async (phoneNumber) => {
  // Check in 'client' table for phoneNumber
  let { data: phoneData, error: phoneError } = await supabase
    .from("client")
    .select("phoneNumber")
    .eq("phoneNumber", phoneNumber);

  if (phoneError) {
    console.error("Error fetching phone number data:", phoneError);
    return false;
  }

  if (phoneData && phoneData.length > 0) {
    return false; // Phone number is already taken
  }

  return true; // Phone number is available
};
// Function to register the user using Supabase Auth
export const registerAuthUser = async (email, password) => {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
  });

  if (error) {
    return {
      success: false,
      authError: error.message || "Error registering user.",
    };
  }

  // Check if the user is returned or if email confirmation is required
  if (data?.user) {
    return { success: true, user: data.user }; // Return the user
  }

  return { success: true, user: null }; // User may need to verify email
};

// Function to get all rows
export const fetchAllArtists = async (tableName) => {
  try {
    const { data, error } = await supabase
      .from(tableName)
      .select("*")
      .not("enEspera", "eq", true);

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error("Error fetching rows:", error.message);
    throw error;
  }
};


// Function to get all rows by attribute
export const fetchAllArtistsByGenre = async (
  tableName,
  attributeName,
  attributeValue,
) => {
  try {
    const { data, error } = await supabase
      .from(tableName)
      .select("*")
      .eq(attributeName, attributeValue);

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error("Error fetching rows:", error.message);
    throw error;
  }
};

export const getUserRoleByID = async (userID) => {
  try {
    // First, try to find the user in the "artist" table
    const artistData = await getArtistByID(userID);
    if (artistData) {
      return { role: "artist", ...artistData };
    }

    // If not found in "artist", check the "client" table
    const clientData = await getClientByID(userID);
    if (clientData) {
      return { role: "client", ...clientData };
    }

    // If the user is not found in either table, return null or an appropriate message
    return { error: "User not found in any role." };
  } catch (error) {
    console.error("Error fetching user role:", error.message);
    throw error;
  }
};

