import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TuneIcon from "@mui/icons-material/Tune";
import {
  fetchAllArtists,
  fetchAllArtistsByGenre,
} from "../../functions/general";
import {
  getArtistsByGenre,
  getArtistsByGenres,
  getArtistsStageName,
  newGetArtistsByGenres,
} from "../../functions/artist";
import { useNavigate, useLocation } from "react-router-dom";
import lupa from "../../assets/images/lupa.png";
import { fetchAllGenres } from "../../functions/genre";

import { useIntl } from "react-intl";

const SearchButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  backgroundColor: "#af8e56",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#af8e56",
  },
}));


const SearchBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#272727",
  borderRadius: "12px",
  padding: theme.spacing(0.5, 2),
  marginTop: theme.spacing(4),
  width: "100%",
  border: "1px solid #A4A4A4",
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
  width: "100%",
}));

const normalizeText = (text) =>
  text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

const SearchBar = ({ onSearch, searchCriteriaPage, setSearchCriteriaPage, isHomePage }) => {
  const [searchCriteria, setSearchCriteria] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchSuggestions = async (query) => {
    if (query.trim() === "") {
      setSuggestions([]);
      return;
    }

    try {
      const normalizedQuery = normalizeText(query);

      // Obtener artistas y géneros
      const allArtists = await fetchAllArtists("artist");
      const genres = await fetchAllGenres();
      console.log(genres)
      // Filtrar artistas y géneros según la búsqueda
      const matchedSuggestionsArtists = allArtists
        .flatMap((artist) => [artist.genre, artist.stageName])
        .filter(
          (item) =>
            item && normalizeText(item).includes(normalizedQuery)
        );

      const matchedSuggestionsGenres = genres.filter(
        (item) => item && normalizeText(item).includes(normalizedQuery)
      );

      // Combinar y eliminar duplicados
      setSuggestions([...new Set([...matchedSuggestionsArtists, ...matchedSuggestionsGenres])]);

    } catch (error) {
      console.error("Error fetching suggestions:", error.message);
    }
  };




  useEffect(() => {
    if (location.state?.searchCriteria) {
      setSearchCriteria(location.state.searchCriteria);
      searchArtists(location.state.searchCriteria);
    }
  }, [location.state]);

  const searchArtists = async (criteria = searchCriteria) => {
    let results = [];
    if (isHomePage) {
      navigate("/search", { state: { searchCriteria: criteria } });
    } else {
      try {
        if (criteria === "") {
          results = await fetchAllArtists("artist");
        } else {
          const genres = criteria.split(",");
          if (genres.length > 1) {
            results = await getArtistsByGenres(genres);
          } else {
            results = await getArtistsByGenre(criteria);
          }
          if (results.length === 0) {
            results = await getArtistsStageName(criteria);
          }
        }
        setSearchCriteriaPage(criteria);
        onSearch(results);
      } catch (error) {
        console.error("Error during search:", error.message);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      searchArtists();
    }
  };
  const intl = useIntl();

  return (
    <SearchBox>
      <StyledAutocomplete
        freeSolo
        options={suggestions}
        onInputChange={(event, newInputValue) => {
          setSearchCriteria(newInputValue);
          fetchSuggestions(newInputValue);
        }}
        onChange={(event, value) => {
          setSearchCriteria(value || "");
        }}
        inputValue={searchCriteria}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={intl.formatMessage({ id: "placeholder", defaultMessage: "Busca: Pop, Rock, Baladas, etc..." })}

            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={lupa}
                    alt={intl.formatMessage({ id: "lupaAlt", defaultMessage: "Lupa" })}
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: "8px",
                      marginLeft: "0px",
                    }}
                  />
                </InputAdornment>
              ),
              style: { color: "#ffffff" },
            }}
            sx={{ backgroundColor: "#272727" }}
            onKeyPress={handleKeyPress}
          />
        )}
      />
      <SearchButton variant="contained" onClick={() => searchArtists()}>
        {intl.formatMessage({ id: "buttonText", defaultMessage: "Buscar" })}

      </SearchButton>
    </SearchBox>
  );
};

export default SearchBar;
