import React, { useEffect, useState } from "react";
import { Typography, styled, Box, Grid } from "@mui/material";
import EventForm from './createFaroForm';
import EventQuote from "../components/artistComponents/EventQuote";
import { useLocation, useParams } from "react-router-dom";
import { getFaroById } from '../functions/faro';
import { useIntl } from "react-intl";


const CreationTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: '400',
  marginBottom: '1vh',
  fontStyle: "normal",
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
    textAlign: 'left',
  },
}));

const CreateFaroPage = () => {
  const intl = useIntl();

  const location = useLocation();
  const { faroId } = useParams();
  const [faroData, setFaroData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const artist = location.state?.artist;
  const date = location.state?.date;
  const startTime = location.state?.startTime;
  const endTime = location.state?.endTime;
  useEffect(() => {
    const fetchFaroData = async () => {
      if (faroId) {
        try {
          setIsLoading(true);
          const data = await getFaroById(faroId);
          if (data.details) {
            data.details = JSON.parse(data.details);
          }
          setFaroData(data);
        } catch (error) {
          console.error('Error fetching Faro data:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchFaroData();
  }, [faroId]);

  return (
    <div>
      <Box sx={{ backgroundColor: "#171717", height: "4vh" }}></Box>
      <Box
        sx={{
          marginRight: { xs: "16px", sm: "32px", md: "64px", lg: "158px" },
          marginLeft: { xs: "16px", sm: "32px", md: "64px", lg: "158px" },
          marginTop: "50px",
          marginBottom: "100px",
        }}
      >
        <CreationTitle style={{ fontFamily: "Poppins" }}>
          {faroId
            ? intl.formatMessage({ id: "createFaroPage.editEvent", defaultMessage: "Edita un" })
            : intl.formatMessage({ id: "createFaroPage.createEvent", defaultMessage: "Crea un" })
          } <span style={{ fontWeight: '600' }}>{intl.formatMessage({ id: "createFaroPage.event", defaultMessage: "evento" })}</span>
        </CreationTitle>
        {isLoading ? (
          <Typography variant="h6" align="center">
            {intl.formatMessage({ id: "createFaroPage.loading", defaultMessage: "Cargando datos del evento..." })}
          </Typography>

        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <EventForm
                faroData={faroData}
                date={date}
                startTime={startTime}
                endTime={endTime}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: { xs: 'none', md: 'block' } }}>
              <Box sx={{ position: "sticky", top: "100px" }}>
                <EventQuote
                  artistName={artist?.stageName || intl.formatMessage({ id: "createFaroPage.artistNotSpecified", defaultMessage: "Artista no especificado" })}
                  genres={artist?.genres?.length > 0 ? artist.genres : [intl.formatMessage({ id: "createFaroPage.genreNotSpecified", defaultMessage: "Género no especificado" })]} // Verifica si es un array
                  hourlyRate={artist?.rate || 0}
                  date={date || intl.formatMessage({ id: "createFaroPage.dateNotSpecified", defaultMessage: "Fecha no especificada" })}
                  timeRange={`${startTime || intl.formatMessage({ id: "createFaroPage.start", defaultMessage: "Inicio" })} - ${endTime || intl.formatMessage({ id: "createFaroPage.end", defaultMessage: "Fin" })}`}
                  complements={[
                    { name: intl.formatMessage({ id: "createFaroPage.complementMicrophone", defaultMessage: "Micrófono" }), price: 50000 },
                    { name: intl.formatMessage({ id: "createFaroPage.complementAmplifier", defaultMessage: "Amplificador" }), price: 80000 },
                  ]}
                  total={(artist?.rate*1.3 || 0)}
                />


              </Box>
            </Grid>



          </Grid>
        )}
      </Box>
    </div>
  );
};

export default CreateFaroPage;
