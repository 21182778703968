import React, { useEffect, useState } from "react";
import { Typography, Container, Grid, Box } from "@mui/material";
import SearchBar from "../components/SearchBar/SearchBar";
import ArtistCard from "../components/ArtistCard/ArtistCard";
import { fetchAllArtists } from "../functions/general";
import { useLocation } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material"
import { useIntl } from "react-intl";

export default function ArtistSearchScreen({ popup }) {
  const [artists, setArtists] = useState([]);
  const location = useLocation();
  const [searchCriteriaPage, setSearchCriteriaPage] = useState(
    location.state?.searchCriteria || ""
  );

  const handleSearch = (results) => {
    setArtists(results);
  };

  useEffect(() => {
    const fetchArtists = async () => {
      let results = await fetchAllArtists("artist");
      setArtists(results);
    };
    if (searchCriteriaPage === "") {
      fetchArtists();
    }
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const intl = useIntl();

  return (
    <Box sx={{ flexGrow: 1, minHeight: "100vh", position: "relative", mb: 5 }}>
      <Box sx={{ backgroundColor: "#171717", height: "9vh" }}></Box>

      <Box
        sx={{
          position: "absolute",
          top: "7vh",
          left: 0,
          right: 0,
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "center",
          padding: 0,
          margin: 0,
        }}
      >
        {isSmallScreen && (
          <SearchBar
            onSearch={handleSearch}
            searchCriteriaPage={searchCriteriaPage}
            setSearchCriteriaPage={setSearchCriteriaPage}
            artists={artists}
            sx={{
              padding: 10,
              margin: 10,
            }}
          />

        )}

        {!isSmallScreen && (
          <Box
            sx={{
              maxWidth: { sm: "400px", md: "500px", lg: "950px" },
              width: "100%",
              padding: 0,
              margin: 0,
            }}
          >

            <SearchBar
              onSearch={handleSearch}
              searchCriteriaPage={searchCriteriaPage}
              setSearchCriteriaPage={setSearchCriteriaPage}
              artists={artists}
              sx={{
                padding: 0,
                margin: 0,
              }}
            />
          </Box>
        )}
      </Box>




      <Box sx={{ backgroundColor: "#fdfdfd", height: "5vh" }}></Box>

      <Box sx={{ backgroundColor: "#fdfdfd", py: 2 }}>
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            color: "#171717",
            display: "block",
            fontFamily: "Poppins",
          }}
        >
          {searchCriteriaPage ? (
            <>
              <Typography
                component="span"
                sx={{
                  fontWeight: 200,
                  color: "#171717",
                  fontFamily: "Poppins",
                  fontSize: "2.2rem",
                }}
              >
                {intl.formatMessage({ id: "resultsFor", defaultMessage: "Resultados para" })} &nbsp;
              </Typography>

              <Typography
                component="span"
                sx={{
                  fontWeight: 700,
                  color: "#171717",
                  fontFamily: "Poppins",
                  fontSize: "2.2rem",
                }}
              >
                {searchCriteriaPage}
              </Typography>
            </>
          ) : (
            <Typography
              component="span"
              sx={{
                fontWeight: 700,
                color: "#171717",
                fontFamily: "Poppins",
                fontSize: "2.2rem",
              }}
            >
              {intl.formatMessage({ id: "exploreArtists", defaultMessage: "Explorar artistas" })}
            </Typography>

          )}
          <Typography
            sx={{
              fontWeight: 100,
              fontFamily: "Poppins",
              fontSize: "1rem",
              color: "#626262",
              paddingTop: 2
            }}
          >
            {intl.formatMessage({ id: "related", defaultMessage: "Relacionado:" })}
            <Typography
              component="span"
              sx={{
                fontWeight: 500,
                fontFamily: "Poppins",
                fontSize: "1rem",
                color: "#626262"
              }}
            >
              &nbsp;{intl.formatMessage({ id: "relatedGenres", defaultMessage: "Ballad Acoustic Pop Rock" })}
            </Typography>
          </Typography>
        </Typography>
      </Box>


      <Container maxWidth={false} sx={{ mt: 8, maxWidth: '85vw' }}>
        {artists.length > 0 && (
          <Grid container spacing={3}>
            {artists.map((artist) => (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={artist.id}>
                <ArtistCard artist={artist} popup={popup} />
              </Grid>

            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
}
