import React from 'react';
import { Box, TextField, Typography } from '@mui/material';

function InputField({ label, id, required, value, onChange }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '240px',
        width: '100%',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem", lg: "1.2rem" },
          fontWeight: 300,
          color: 'text.secondary',
          fontFamily: "Poppins",
        }}
      >
        {label}
        {required && (
          <Typography
            component="span"
            sx={{
              fontWeight: 500,
              color: 'text.primary',
            }}
          >
            *
          </Typography>
        )}
      </Typography>
      <TextField
        id={id}
        required={required}
        fullWidth
        variant="outlined"
        value={value}
        onChange={onChange}
        sx={{
          mt: 2,
          minHeight: '47px',
          borderRadius: '8px',
        }}
      />
    </Box>
  );
}

export default InputField;
