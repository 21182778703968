import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useIntl } from 'react-intl'; 
import footer from '../../assets/images/footer.jpg';

const StyledFooter = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 4),
  color: "#fff",
  textAlign: "center",
  backgroundImage: `url(${footer})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}));

function Footer() {
  const intl = useIntl(); 

  return (
    <StyledFooter>
      <Typography variant="h4" component="h2" gutterBottom>
        {intl.formatMessage({ id: "footer.title" })}
      </Typography>
    </StyledFooter>
  );
}

export default Footer;
