import React from "react";
import { Box, Typography } from "@mui/material";
import InputField from "./InputField";
import { useIntl } from "react-intl";

function ContactInfo({ formData, handleChange }) {
  const intl = useIntl(); // Inicialización de intl para traducción

  const fields = [
    {
      label: intl.formatMessage({ id: "contactInfo.fullName", defaultMessage: "Nombre completo" }),
      id: "fullName",
      required: true
    },
    {
      label: intl.formatMessage({ id: "contactInfo.phoneNumber", defaultMessage: "Número de teléfono" }),
      id: "phoneNumber",
      required: true
    },
    {
      label: intl.formatMessage({ id: "contactInfo.emailAddress", defaultMessage: "Dirección de correo electrónico" }),
      id: "emailAddress",
      required: true
    }
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <Typography
        variant="h2"
        sx={{ fontWeight: "bold", color: "text.primary", mb: 2, fontSize: "2vw" }}
      >
        {intl.formatMessage({ id: "contactInfo.title", defaultMessage: "Información de contacto" })}
      </Typography>
      {fields.map((field) => (
        <InputField
          key={field.id}
          label={field.label}
          id={field.id}
          required={field.required}
          value={formData[field.id] || ""} 
          onChange={(e) => handleChange(field.id, e.target.value)} 
        />
      ))} 
    </Box>
  );
}

export default ContactInfo;
