import React, { useState } from 'react';
import { Box, Typography, Grid, Card, CardMedia, CardContent } from '@mui/material';
import { styled } from '@mui/system';
import { useIntl } from "react-intl";


const categories = [
  { name: 'Ballad', image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/ecf34e1df3a0aace4d18df6aa92966e2a0eac9bbd81f8e42f02aae5a904868be?placeholderIfAbsent=true&apiKey=7ef19faf957d428881711f97cdd2525d' },
  { name: 'POP', image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/3318a84ee22183c575554f67178df9fb8978e71a687dbe532a8e297a46d02e08?placeholderIfAbsent=true&apiKey=7ef19faf957d428881711f97cdd2525d' },
  { name: 'Rock', image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/756fbf95467b95a20956d6d16759efd1ddc2b072fb74c9c14fbfbaa639ea5b4c?placeholderIfAbsent=true&apiKey=7ef19faf957d428881711f97cdd2525d' },
  { name: 'Acoustic', image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/43b03b911ce21e175fa37abb4d4d3558573fa81f73d74c350a20104f48bb8ff9?placeholderIfAbsent=true&apiKey=7ef19faf957d428881711f97cdd2525d' },
  { name: 'Reggaeton', image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/cdc5ec10ef72eae2e684ccc4522da190ed0d5e2a4a708aa372305c19ac55b1f5?placeholderIfAbsent=true&apiKey=7ef19faf957d428881711f97cdd2525d' },
  { name: 'Salsa', image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/1f2a43d0dc5975de287637ded83e1189fdab3c8772c0baf207f61a3625deb3d3?placeholderIfAbsent=true&apiKey=7ef19faf957d428881711f97cdd2525d' }
];

const CategoryCard = styled(Card)(({ selected }) => ({
  position: 'relative',
  minHeight: '130px',
  width: '100%',
  borderRadius: '8px',
  textAlign: 'center',
  overflow: 'hidden',
  border: selected ? '3px solid #AC8C46' : '3px solid transparent',
  transition: 'border-color 0.3s ease',
  cursor: 'pointer',
}));

const CategorySelection = ({ value, onChange }) => {
  const [selectedCategories, setSelectedCategories] = useState(value || []);
  const intl = useIntl();
  const handleCategoryClick = (categoryName) => {
    let newSelectedCategories;
    if (selectedCategories.includes(categoryName)) {
      // Remove category if already selected
      newSelectedCategories = selectedCategories.filter(c => c !== categoryName);
    } else if (selectedCategories.length < 3) {
      // Add category if less than 3 are selected
      newSelectedCategories = [...selectedCategories, categoryName];
    } else {
      return; // Do nothing if already 3 categories are selected
    }

    setSelectedCategories(newSelectedCategories);
    onChange(newSelectedCategories); // Notify parent component
  };

  return (
    <Box sx={{ flexGrow: 1, minWidth: '240px', width: '100%' }}>
      <Typography variant="h4" fontWeight="600" color="black" gutterBottom>
        {intl.formatMessage({ id: "categorySelection.title", defaultMessage: "¿Qué tipo de evento estás organizando?" })}
      </Typography>

      <Typography variant="body1" color="text.secondary" sx={{ marginBottom: 2 }}>
        {intl.formatMessage({ id: "categorySelection.subtitle", defaultMessage: "Categoría" })}{" "}
        <Typography component="span" fontWeight="500" color="text.primary">
          * {intl.formatMessage({ id: "categorySelection.limitMessage", defaultMessage: "(Selecciona un máximo de 3 opciones)" })}
        </Typography>
      </Typography>



      <Grid container spacing={2} justifyContent="center">
        {categories.map((category, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={2} display="flex" justifyContent="center">
            <CategoryCard
              selected={selectedCategories.includes(category.name)}
              onClick={() => handleCategoryClick(category.name)}
            >
              <CardMedia
                component="img"
                image={category.image}
                alt={category.name}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              <CardContent sx={{ position: 'relative', zIndex: 1, color: '#fff', fontWeight: '600', textTransform: 'uppercase' }}>
              </CardContent>
            </CategoryCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CategorySelection;

